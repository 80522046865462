import { ControllerFlowAPI, IWixAPI } from '@wix/yoshi-flow-editor';
import { LoyaltyEarningRule, Status } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';
import { Tier, TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';
import { getTiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/http';
import { LoyaltyProgram, ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';
import {
  WIX_STORES as STORES_APP_DEF_ID,
  WIX_EVENTS as EVENTS_APP_DEF_ID,
  WIX_BOOKINGS as BOOKINGS_APP_DEF_ID,
} from '@wix/app-definition-ids';

import { LoadDataResult } from './loadData';

const shouldShowCouponRewards = async (wixCodeApi: IWixAPI) => {
  const isStoresAppInstalled = await wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: STORES_APP_DEF_ID,
    sectionId: 'product_page',
  });

  const isEventsAppInstalled = await wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: EVENTS_APP_DEF_ID,
    sectionId: 'events',
  });

  const isBookingsAppInstalled = await wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: BOOKINGS_APP_DEF_ID,
    sectionId: 'bookings_list',
  });

  return isStoresAppInstalled || isEventsAppInstalled || isBookingsAppInstalled;
};

export async function getDemoContent(
  flowAPI: ControllerFlowAPI,
  loyaltyProgram: LoyaltyProgram,
): Promise<LoadDataResult> {
  const { translations, httpClient, controllerConfig } = flowAPI;
  const { t } = translations;
  const { wixCodeApi } = controllerConfig;
  const showCouponRewards = await shouldShowCouponRewards(wixCodeApi);
  let tiers: Tier[] = [];
  let tiersProgramSettings: TiersProgramSettings = {};

  loyaltyProgram.status = ProgramStatus.ACTIVE;

  const earningRules: LoyaltyEarningRule[] = [
    {
      conversionRate: {
        moneyAmount: 1,
        points: 1,
        configs: [
          {
            moneyAmount: 1,
            points: 1,
          },
        ],
      },
      status: Status.ACTIVE,
      title: t('app.fixtures.earning-rule-a.title'),
      triggerActivityType: 'wix-restaurants/orderSubmitted',
    },
    {
      fixedAmount: {
        points: 50,
        configs: [
          {
            points: 50,
          },
        ],
      },
      status: Status.ACTIVE,
      title: t('app.fixtures.earning-rule-b.title'),
      triggerActivityType: 'auth/register',
    },
  ];

  const templateCouponId = '44d413ef-b654-46b6-a410-66966b3618ba';

  const rewards: Reward[] = [
    {
      active: true,
      discountAmount: {
        configsByTier: [
          {
            amount: '1',
            costInPoints: 10,
          },
        ],
      },
      name: t('app.fixtures.reward-a.title'),
      type: RewardType.DISCOUNT_AMOUNT,
    },
  ];

  if (showCouponRewards) {
    rewards.push({
      active: true,
      coupon: {
        templateId: templateCouponId,
        configs: [
          {
            requiredPoints: 15,
          },
        ],
      },
      name: t('app.fixtures.reward-b.title'),
      type: RewardType.COUPON,
    });
  }

  const coupons: CouponReference[] = [
    {
      couponId: templateCouponId,
      name: t('app.fixtures.coupon-a.display-name'),
      specification: {
        percentOffRate: 100,
      },
    },
  ];

  const { data } = await httpClient.request(getTiersProgramSettings({}));
  const tiersStatus = data.programSettings?.status;

  tiersProgramSettings = {
    status: tiersStatus,
    baseTierDefinition: {
      name: t('app.fixtures.tier-basic.title'),
      icon: {
        url: 'shapes/11062b_0a8e17936d5b4d46971649be036c781b.svg',
      },
    },
  };

  tiers = [
    {
      id: 'fa4d72b2-096f-4fc7-89c4-d26163a4674a',
      tierDefinition: {
        name: t('app.fixtures.tier-silver.title'),
        icon: {
          url: 'shapes/11062b_0a8e17936d5b4d46971649be036c781b.svg',
        },
      },
      requiredPoints: 100,
    },
    {
      id: '97b374d4-e8c7-47d0-a8e6-f0c1d9625fb4',
      tierDefinition: {
        name: t('app.fixtures.tier-gold.title'),
        icon: {
          url: 'shapes/11062b_0a8e17936d5b4d46971649be036c781b.svg',
        },
      },
      requiredPoints: 200,
    },
  ];

  earningRules[0].conversionRate?.configs?.push({
    moneyAmount: 2,
    points: 3,
    tierId: tiers[0].id,
  });

  earningRules[0].conversionRate?.configs?.push({
    moneyAmount: 3,
    points: 7,
    tierId: tiers[1].id,
  });

  return {
    earningRules,
    rewards,
    coupons,
    tiersProgramSettings,
    tiers,
    loyaltyProgram,
  };
}
