import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { listEarningRules } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/http';
import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { queryCouponTemplate } from '@wix/ambassador-loyalty-v1-coupon/http';
import { CouponReference } from '@wix/ambassador-loyalty-v1-coupon/types';
import { getTiersProgram } from '@wix/ambassador-loyalty-v1-tier/http';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';

export type LoadDataResult = Awaited<ReturnType<typeof loadData>>;

const WARMUP_DATA_KEY = 'loadData';

export async function loadData(flowAPI: ControllerFlowAPI, preloadedLoyaltyProgram?: LoyaltyProgram) {
  const { httpClient } = flowAPI;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { isSSR, isViewer } = flowAPI.environment;
  const useWarmupData = isViewer;
  const { warmupData } = wixCodeApi.window;

  // Reuse backend API responses from SSR on the client-side
  if (useWarmupData && !isSSR) {
    const dataFromSSR = warmupData.get(WARMUP_DATA_KEY);
    if (dataFromSSR) {
      return dataFromSSR;
    }
  }

  const rewardsAndCouponsPromise = httpClient.request(listRewards({})).then(async ({ data }) => {
    const rewards = data.rewards ?? [];
    let coupons: CouponReference[] = [];

    if (rewards.length) {
      coupons = await getCoupons(flowAPI, rewards);
    }

    return { rewards, coupons };
  });

  const loyaltyProgramPromise = !preloadedLoyaltyProgram ? httpClient.request(getLoyaltyProgram({})) : undefined;
  const earningRulesPromise = httpClient.request(listEarningRules({}));
  const tiersProgramPromise = httpClient.request(getTiersProgram({}));

  const [{ rewards, coupons }, earningRulesResponse, tiersProgramResponse, loyaltyProgramResponse] = await Promise.all([
    rewardsAndCouponsPromise,
    earningRulesPromise,
    tiersProgramPromise,
    loyaltyProgramPromise,
  ]);

  const earningRules = earningRulesResponse.data.earningRules ?? [];
  const tiersProgramSettings = tiersProgramResponse?.data.programSettings ?? {};
  const tiers = tiersProgramResponse?.data.tiers ?? [];
  const loyaltyProgram = preloadedLoyaltyProgram || loyaltyProgramResponse?.data.loyaltyProgram!;

  const data = {
    earningRules,
    rewards,
    coupons,
    tiersProgramSettings,
    tiers,
    loyaltyProgram,
  };

  // Forward backend API responses from SSR to client-side
  if (useWarmupData && isSSR) {
    warmupData.set(WARMUP_DATA_KEY, data);
  }

  return data;
}

async function getCoupons(flowAPI: ControllerFlowAPI, rewards: Reward[]) {
  let coupons: CouponReference[] = [];
  const templateIds = rewards.filter((reward) => reward.coupon?.templateId).map((reward) => reward.coupon?.templateId);

  if (templateIds.length) {
    const { data } = await flowAPI.httpClient.request(
      queryCouponTemplate({
        query: {
          filter: JSON.stringify({ id: { $in: templateIds } }),
        },
      }),
    );

    if (data.couponReferences) {
      coupons = data.couponReferences;
    }
  }

  return coupons;
}
