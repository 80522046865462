import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { MEMBERS_AREA as MEMBERS_AREA_APP_DEF_ID } from '@wix/app-definition-ids';

import { MY_REWARDS_APP_DEF_ID, MY_REWARDS_WIDGET_ID, MY_REWARDS_SECTION_ID } from '../constants';

export async function getMyRewardsPageUrl(flowAPI: ControllerFlowAPI): Promise<string> {
  const { isEditor } = flowAPI.environment;
  const { wixCodeApi } = flowAPI.controllerConfig;

  if (isEditor) {
    return ''; // Can't use getPublicAPI() in Editor view mode
  }

  const membersApi = await wixCodeApi.site.getPublicAPI(MEMBERS_AREA_APP_DEF_ID);

  try {
    // Will throw if "My Rewards" app is not installed
    const myRewardsUrl = await membersApi?.getSectionUrl({
      appDefinitionId: MY_REWARDS_APP_DEF_ID,
      sectionId: MY_REWARDS_SECTION_ID,
      widgetId: MY_REWARDS_WIDGET_ID,
    });

    return myRewardsUrl ?? '';
  } catch {}

  return '';
}
