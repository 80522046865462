import { CreateControllerFn, IUser } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';
import { ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { loadLoyaltyCouponNames } from '@wix/loyalty-coupon-names';
import { loyaltyJoinNowOrViewPoints, loyaltyProgramOpen } from '@wix/bi-logger-loyalty-uou/v2';

import { SettingsEvents, TabState } from '../../types/settings';
import type { ControllerProps } from './Widget';
import {
  isMyRewardsPageInstalled,
  navigateToMyRewardsPage,
  promptLogin,
  loadData,
  getDemoContent,
  createCurrencyFormatter,
} from '../../utils';
import { toSimpleRules } from '../../utils/to-simple-rules';
import { toSimpleRewards } from '../../utils/to-simple-rewards';
import { toSimpleTiers } from '../../utils/to-simple-tiers';

const createController: CreateControllerFn = async ({
  controllerConfig: { config, setProps, wixCodeApi },
  flowAPI,
}) => {
  const componentEventHandler = createEventHandler<SettingsEvents>(config.publicData.COMPONENT || {});
  const { httpClient } = flowAPI;
  const { isViewer, isEditor } = flowAPI.environment;
  const formatCurrency = createCurrencyFormatter(flowAPI);

  const setWidgetProps = (props: Partial<ControllerProps>) => {
    setProps(props);
  };

  // See: https://wix.slack.com/archives/C01480U2SAF/p1643623573887269
  setProps({ fitToContentHeight: true });

  return {
    async pageReady() {
      componentEventHandler.on('tabState', (tabState: TabState) => setWidgetProps({ tabState }));
      componentEventHandler.onReset(() => setWidgetProps({ tabState: TabState.BeforeSignUp }));

      try {
        const [loyaltyProgramResponse, couponNames] = await Promise.all([
          !isViewer ? httpClient.request(getLoyaltyProgram({})) : undefined,
          loadLoyaltyCouponNames({ i18n: flowAPI.translations.i18n, formatCurrency }),
        ]);

        const preloadedLoyaltyProgram = loyaltyProgramResponse?.data.loyaltyProgram;
        const isProgramActive = preloadedLoyaltyProgram?.status === ProgramStatus.ACTIVE;
        const useDemoContent = !isViewer && !isProgramActive;

        const { coupons, earningRules, rewards, tiers, tiersProgramSettings, loyaltyProgram } = useDemoContent
          ? await getDemoContent(flowAPI, preloadedLoyaltyProgram!)
          : await loadData(flowAPI, preloadedLoyaltyProgram);

        const hasMyRewardsPage = await isMyRewardsPageInstalled(flowAPI);

        const handleNavigateToMyRewardsPage = async () => {
          const isLoggedIn = wixCodeApi.user.currentUser.loggedIn;

          if (isViewer) {
            flowAPI.bi?.report(
              loyaltyJoinNowOrViewPoints({
                button: isLoggedIn ? 'view_points' : 'join_program',
                role: wixCodeApi.user.currentUser.role,
              }),
            );
          }

          const shouldNavigateToMyRewardsPage = isLoggedIn ? true : await promptLogin(flowAPI);

          if (shouldNavigateToMyRewardsPage) {
            await navigateToMyRewardsPage(flowAPI);
          }
        };

        setWidgetProps({
          simpleRules: toSimpleRules({ rules: earningRules, flowAPI, loyaltyProgram }),
          simpleRewards: toSimpleRewards({
            rewards,
            coupons,
            flowAPI,
            loyaltyProgram,
            couponNames,
          }),
          simpleTiers: toSimpleTiers(tiers),
          loyaltyProgram,
          isLoggedIn: isEditor ? false : wixCodeApi.user.currentUser.loggedIn,
          hasMyRewardsPage,
          onNavigateToMyRewardsPage: handleNavigateToMyRewardsPage,
          tiersProgramSettings,
        });

        wixCodeApi.user.onLogin((user: IUser) => {
          setWidgetProps({ isLoggedIn: user.loggedIn });
        });

        // NOTE: "action=signup" param is used in emails
        if (isViewer && !wixCodeApi.user.currentUser.loggedIn) {
          const queryParams = wixCodeApi.location.query;
          if (queryParams.action === 'signup') {
            promptLogin(flowAPI, 'signup');
          }
        }

        if (isViewer) {
          flowAPI.bi?.report(
            loyaltyProgramOpen({
              response: loyaltyProgram.status,
              role: wixCodeApi.user.currentUser.role,
            }),
          );
        }
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          flowAPI.reportError(error);
        }
        setWidgetProps({
          hasError: true,
        });
        flowAPI.bi?.report(
          loyaltyProgramOpen({
            role: wixCodeApi.user.currentUser.role,
          }),
        );
      }
    },
    updateConfig(_$w, newConfig) {
      componentEventHandler.notify(newConfig.publicData.COMPONENT || {});
    },
  };
};

export default createController;
