import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { createCurrencyFormatter } from '../createCurrencyFormatter';

interface CreateRuleDescriptionParams {
  rule: LoyaltyEarningRule;
  flowAPI: ControllerFlowAPI;
  loyaltyProgram: LoyaltyProgram;
  configIndex: number;
}

export const createRuleDescription = ({ rule, flowAPI, loyaltyProgram, configIndex }: CreateRuleDescriptionParams) => {
  const formatCurrency = createCurrencyFormatter(flowAPI);
  const { t } = flowAPI.translations;
  const customPointsName = loyaltyProgram.pointDefinition?.customName;
  const { conversionRate, fixedAmount } = rule;

  if (conversionRate) {
    const { moneyAmount, points } = conversionRate.configs![configIndex]!;
    const key = customPointsName
      ? 'app.rule-description.conversion-rate-custom'
      : 'app.rule-description.conversion-rate';

    return t(key, {
      pointsName: customPointsName,
      pointsAmount: points,
      moneyAmount: formatCurrency(moneyAmount ?? 0),
    });
  } else if (fixedAmount) {
    const { points } = fixedAmount.configs![configIndex]!;
    const key = customPointsName ? 'app.rule-description.fixed-amount-custom' : 'app.rule-description.fixed-amount';

    return t(key, {
      pointsName: customPointsName,
      pointsAmount: points,
    });
  }

  return '';
};
